<template>
  <div v-loading="loading">
    <el-button type="primary" @click="handleClear">清空缓存</el-button>
    <span v-show="succ"
      >清空缓存成功！<span
        v-text="moment(clearTime).format('YYYY-MM-DD HH:mm:ss')"
      ></span
    ></span>
  </div>
</template>

<script>
import { clear } from "@/api/system/cache.js";

export default {
  data() {
    return {
      loading: false,
      succ: false,
      clearTime: {},
    };
  },
  methods: {
    handleClear() {
      this.loading = true;
      clear().then((res) => {
        this.clearTime = res;
        this.succ = true;
        this.loading = false;
      });
    },
  },
  created() {},
};
</script>

<style></style>
